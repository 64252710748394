













































































































































































import { defineComponent, reactive, toRefs } from '@vue/composition-api'

export default defineComponent({
  name: 'GoodHomeworkDesk',
  setup() {
    const showState = reactive({
      showsj: false,
      advantage: false
    })
    const productAdvantage = [
      {
        title: '高质量题源',
        icon: 'gjz',
        list: [
          '1300万+精标题库',
          'AI智能生产与标签标注',
          '自动沉淀区域高频使用题'
        ]
      },
      {
        title: '作业设计',
        icon: 'gzl',
        list: ['全方位AI评估诊断', '全流程数字化作业设计', '多角色作业设计研修']
      },
      {
        title: '作业数据采集分析',
        icon: 'swj',
        list: [
          '多种采集方式支撑多种作业场景',
          '过程和结果学情数据综合分析',
          '共性错题巩固练、个性错题精准练'
        ]
      }
    ]
    const pics = {
      pfnew: require('./assets/product-function-new.png'),
      cpgn: require('./assets/product-function-img.png'),
      onebg: require('../../assets/one-bg.png'),
      mp: require('./assets/product-printer.png'),
      monitor: require('./assets/data-monitoring.png'),
      dwd: require('./assets/monitoring-screenshot.png'),
      dmnew: require('./assets/data-monitoring-new.png')
    }

    const handleSetShow = (type: string) => {
      showState[type] = true
    }
    return { ...toRefs(showState), productAdvantage, pics, handleSetShow }
  }
})
